import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import vueRouter from "vue-router"
import { routes } from "./router.js";
import VueTyperPlugin from 'vue-typer'
import "../css/style.css"
import "../css/fontawesome6.all.css"

Vue.use(VueTyperPlugin)

Vue.use(vueRouter);
const router = new vueRouter({
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')