<template>
  <div class="container-fluid">
    <div class="row">
      <transition name="slide" mode="out-in" type="animation">
        <div v-if="off" class="preloader"></div>
      </transition>
      <div id="main">
        <header id="main_header">
          <ul id="menu" class="d-none d-lg-block">
            <router-link
              tag="li"
              class="menu_item"
              v-for="(item, index) in items"
              :key="index"
              :to="item.path"
              ><a @click="slidePass(index)"
                ><i :class="item.icon"></i>
                <h3>{{ item.subject }}</h3></a
              ></router-link
            >
          </ul>
          <div id="menuToggle" class="d-block d-lg-none">
            <a href="#" id="menu_icon" @click="openMenu">
              <i :class="icon"></i>
            </a>
            <ul id="menu_small">
              <router-link
                tag="li"
                v-for="(item, index) in items"
                :key="index"
                :to="item.path"
                ><a @click="slidePassM(index)"
                  ><i :class="item.icon"></i> <span>{{ item.subject }}</span></a
                ></router-link
              >
            </ul>
          </div>
        </header>
        <div id="main_content">
          <transition name="fade" mode="out-in" appear>
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          path: "/",
          subject: "Home",
          icon: "fa fa-home",
          name: "Home",
        },
        {
          path: "/About",
          subject: "About",
          icon: "fa fa-user",
          name: "About",
        },
        {
          path: "/Works",
          subject: "Portfolio",
          icon: "fa fa-briefcase",
          name: "Works",
        },
        {
          path: "/Contact",
          subject: "Contact",
          icon: "fa fa-envelope-open",
          name: "Contact",
        },
      ],
      off: false,
      isMenuOpen: true,
      icon: "fal fa-bars",
    };
  },
  watch: {
    "$route.path": {
      deep: true,
      handler(newVal) {
        if (newVal) {
          for (let inx = 0; inx < this.items.length; inx++) {
            if (this.items[inx].path === newVal) {
              const element = document.getElementsByClassName("menu_item")[inx];
              element.style.background = "#d63031";
              const element_M = document.getElementById("menu_small");
              const element_li = element_M.getElementsByTagName("li")[inx];
              element_li.style.color = "#d63031";
            }
          }
        }
      },
    },
  },
  methods: {
    slidePass(index) {
      this.off = !this.off;
      let parentUl = document.getElementById("menu");

      for (let el = 0; el < parentUl.childNodes.length; el++) {
        parentUl.childNodes[el].style.background = "#2b2a2a";
      }
      const elements = document.getElementsByClassName("menu_item")[index];
      elements.style.background = "#d63031";
    },
    slidePassM(index) {
      this.off = !this.off;
      let parentUl = document.getElementById("menu_small");

      for (let el = 0; el < parentUl.childNodes.length; el++) {
        parentUl.childNodes[el].style.color = "white";
      }

      const elements_M = document.getElementById("menu_small");
      const elements_li = elements_M.getElementsByTagName("li")[index];
      elements_li.style.color = "#d63031";
      const menu_ul = document.getElementById("menu_small");
      menu_ul.style.transform = "translate(-100%, 0)";
      this.isMenuOpen = true;
      this.icon = "fal fa-bars";
    },
    openMenu() {
      const menu_ul = document.getElementById("menu_small");
      if (this.isMenuOpen) {
        menu_ul.style.transform = "none";
        this.icon = "fal fa-times";
        this.isMenuOpen = false;
      } else {
        menu_ul.style.transform = "translate(-100%, 0)";
        this.icon = "fal fa-bars";
        this.isMenuOpen = true;
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.slide-enter-active {
  animation: slideIn 0.7s forwards;
}
.slide-leave-active {
  animation: slideIn 0.7s reverse;
}
.slide-leave {
  height: 0;
}
.slide-enter {
  height: 0;
}

@keyframes slideIn {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}
@keyframes slideOut {
  from {
    height: 100%;
  }
  to {
    height: 0;
  }
}
</style>
