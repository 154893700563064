export const routes = [
  { path: "/", name: "home", component: () => import("./components/Home.vue") },
  {
    path: "/About",
    name: "about",
    component: () => import("./components/About/index.vue"),
  },
  {
    path: "/Works",
    name: "works",
    component: () => import("./components/Works.vue"),
  },
  {
    path: "/Contact",
    name: "contact",
    component: () => import("./components/Contact.vue"),
  },
];
